import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About: React.FunctionComponent<{}> = () => (
    <Layout>
        <SEO title="About"/>
        <h1>Hey!</h1>
        <div
            style={{
                textAlign: "center",
                maxWidth: "640px",
                margin: "auto",
            }}
        >
            <p>
                My name is Rémi Carton, I'm a Software Engineer. I was born in France,
                currently I live in San Diego.
            </p>
            <p>
                Sometimes I tweet from{" "}
                <a href="https://twitter.com/remicarton" target="_blank">
                    @remicarton
                </a> or I toot (?) from <a href="https://hachyderm.io/@rcarton"
                                           target="_blank">@rcarton@hachyderm.io</a>
                .
            </p>
        </div>
    </Layout>
)

export default About
